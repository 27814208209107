<template>
  <div>
    <b-button
      class="btn bg-danger mb-2"
      size="sm"
      @click="showUploadDocumentModal"
    >
      <feather-icon
        icon="FilePlusIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('Upload attachment') }}</span>
    </b-button>
    <component
      :is="getComponent(this,'UploadAttachmentModal',module)"
      ref="upload-attachment-modal"
      module="Documents"
      :parent-id="id"
      @onSubmitForm="submitUploadAttachment"
    />
    <history-loader v-if="loader" />
    <app-timeline v-else>

      <app-timeline-item
        v-for="(document,key) in documentsData"
        :key="key"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ document.notes_title }}</h6>
          <small class="text-muted">{{ document.createdtime | dateFormat('YYYY/MM/DD H:mm:s') }}</small>
        </div>
        <p>{{ document.filename }}</p>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          size="sm"
          variant="outline-primary"
          @click="downloadFile(document,id)"
        >
          {{ $t('Download file') }}
        </b-button>
      </app-timeline-item>
      <b-button
        v-if="more"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        :disabled="loading"
        variant="outline-primary"
        class="mt-2"
        @click="page++"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <span v-if="!loading">{{ $t('More') }} </span>
      </b-button>
    </app-timeline>
  </div>
</template>

<script>
import {
  BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import HistoryLoader from '@/views/modules/core/loader/History.vue'
import UploadAttachmentModal from '@/views/modules/core/edit/UploadAttachmentModal.vue'
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'

export default {
  name: 'DocumentsRelatedWidget',
  components: {
    HistoryLoader,
    AppTimeline,
    AppTimelineItem,
    UploadAttachmentModal,
    BButton,
    BSpinner,
  },
  directives: { Ripple },
  mixins: [coreMethods],
  props: {
    id: {
      type: String,
      default: () => '',
    },
    module: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      page: 0,
      more: false,
      loading: false,
      loader: false,
      documentsData: [],
    }
  },
  watch: {
    page: 'getDocuments',
  },
  created() {
    this.loader = true
    this.getDocuments()
  },
  methods: {
    getDocuments() {
      this.loading = true
      api.fetchRelatedRecords({
        id: this.id,
        module: this.module,
        page: this.page,
        relatedModule: 'Documents',
        relatedModuleLabel: 'Documents',
      }).then(data => {
        this.loading = false
        this.loader = false
        this.more = data.more
        this.documentsData = data.documents
      })
    },
    showUploadDocumentModal() {
      this.$refs['upload-attachment-modal'].$refs['upload-attachment-modal'].show()
    },
    submitUploadAttachment() {
      this.$refs['upload-attachment-modal'].$refs['upload-attachment-modal'].hide()
      this.getDocuments()
    },
  },
}
</script>

<style>

</style>
