<script>
import BeforeListContent from '@/views/modules/core/list/BeforeListContent.vue'
import api from '@/apis/portal'

export default {
  name: 'HelpDeskBeforeListContent',
  extends: BeforeListContent,
  created() {
    this.statusField = 'ticketstatus'
    this.exportField = true
    api.describeModule({ module: this.module }).then(data => {
      const { fields } = data
      let options = []
      Object.values(fields).forEach(field => {
        if (field.name === this.statusField) {
          options = field.type.picklistValues
        }
      })
      this.statusOptions = this.statusOptions.concat(options)
    })
  },
}
</script>

<style scoped>

</style>
