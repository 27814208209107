<template>
  <b-row>

    <b-col
      v-if="totalRecords > 0"
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-start"
    >
      <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('record') }}</span>
    </b-col>
    <!-- Pagination -->
    <b-col
      v-if="totalRecords > perPage"
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-end"
    >

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRecords"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>

    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'ListPagination',
  components: {
    BRow, BCol, BPagination,
  },
  props: {
    totalRecords: {
      type: Number,
      default: () => 0,
    },
    perPage: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    dataMeta() {
      const from = (this.currentPage - 1) * this.perPage + 1
      let to = this.totalRecords
      if (this.perPage < this.totalRecords) {
        to = this.perPage * this.currentPage
        if (to > this.totalRecords) {
          to = this.totalRecords
        }
      }
      return {
        from,
        to,
        of: this.totalRecords,
      }
    },
  },
  watch: {
    // call again the method if the route changes
    currentPage: 'currentPageChange',
  },

  methods: {
    currentPageChange(currentPage) {
      this.$emit('onCurrentPageChange', currentPage)
    },
  },
}
</script>

<style scoped>

</style>
