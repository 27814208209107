<template>
  <upload-form
    :loading="loading"
    :progress="progress"
    @onSubmitForm="submitForm"
  />
</template>

<script>
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'
import UploadForm from './partials/UploadForm.vue'

export default {
  name: 'UploadAttachmentForm',
  components: {
    UploadForm,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: () => '',
    },
    parentId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loading: false,
      progress: 0,
    }
  },
  methods: {
    submitForm(formRules) {
      formRules.validate().then(success => {
        if (success) {
          this.loading = true
          api.upload('uploadAttachment', {
            module: 'Documents',
            file: this.$store.state.portal.formData.file,
            parentId: this.parentId,
          }, progress => {
            this.progress = progress
          }).then(data => {
            this.$store.commit('portal/UPDATE_FORM_DATA', {})
            this.$emit('onSubmitForm', data)
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
