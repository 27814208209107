<template>
  <edit-view-form :module="module" />
</template>

<script>
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'
import EditViewForm from '@/views/modules/core/edit/EditViewForm.vue'
import { formatLineItems } from '@/utils/utils'
import DateUiType from './uitypes/Date.vue'

const { getInventoryModules } = require('@/utils/utils')

export default {
  name: 'Edit',
  components: {
    EditViewForm,
    // eslint-disable-next-line vue/no-unused-components
    DateUiType,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      moduleData: {},
      moduleLabel: '',
      fields: [],
      loading: false,
      formData: {},
      lineItemsData: {},
    }
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {

    },
    submitForm() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.loading = true
          if (getInventoryModules(this.module)) this.formData.LineItems = formatLineItems(this.lineItemsData)
          api.saveRecord({
            module: this.module,
            record: this.formData,
          }).then(data => {
            this.showSuccessNotification(this.$t('Record added Successfully'))
            this.afterSubmitForm(data)
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
    afterSubmitForm(data) {
      this.$router.push({ name: 'detail-view', params: { module: this.module, id: data.record.id } })
    },
  },
}
</script>

<style scoped>

</style>
