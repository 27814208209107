<template>
  <!-- Table Top -->
  <b-row class="m-1">
    <b-col
      cols="12"
      lg="7"
      class="mb-1"
    >
      <b-row>
        <!-- Per Page -->
        <b-col
          lg="4"
          md="4"
          cols="12"
          class="mb-2"
        >
          <label> {{ $t('records') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-1"
          />
        </b-col>
        <b-col
          class="mb-2 mb-md-0"
          lg="4"
          md="4"
          cols="12"
        >
          <b-button-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :class="{ active: queryFilter === 'mine'}"
              @click="toggleQueryFilter('mine',$event)"
            >
              {{ $t('Mine') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :class="{ active: queryFilter === 'all'}"
              @click="toggleQueryFilter('all',$event)"
            >
              {{ $t('All') }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          lg="4"
          md="4"
          cols="12"
          class="text-md-right"
        >
          <b-button-group>
            <b-button
              v-show="isModuleCreateAble"
              class="btn-sm"
              variant="primary"
              @click="createNewRecord(module)"
            >
              {{ $t('Add Record',{ label: singleLabel }) }}
            </b-button>
            <b-button
              v-show="exportField"
              class="btn-sm"
              variant="primary"
              :disabled="$store.state.portal.loaders.exportData"
              @click="exportRecord()"
            >
              <b-spinner
                v-if="$store.state.portal.loaders.exportData"
                small
              />
              <span v-if="!$store.state.portal.loaders.exportData">{{ $t('Export',{ label: singleLabel }) }}</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-col>
    <!-- Search -->
    <b-col
      cols="12"
      lg="5"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            :placeholder="`${$t('Search')}...`"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <v-select
            v-if="statusOptions.length > 1"
            key="value"
            v-model="statusFilter"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            :placeholder="$t('Select Status')"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BButtonGroup, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { getSingleLabelByModuleName } from '@/utils/utils'

export default {
  name: 'BeforeListContent',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BButtonGroup,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    module: {
      type: String,
      default: () => '',
    },
    listColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      statusField: '',
      exportField: false,
      statusFilter: { label: this.$t('All'), value: '' },
      statusOptions: [{
        label: this.$t('All'),
        value: '',
      }],
      queryFilter: '',
      getSingleLabelByModuleName,
    }
  },
  computed: {
    isModuleCreateAble() {
      const currentModule = this.module
      const find = store.state.portal.modules.find(module => module.name === currentModule)
      return !!(find && parseInt(find.create, 0) === 1)
    },
    isModuleEditAble() {
      const currentModule = this.module
      const find = store.state.portal.modules.find(module => module.name === currentModule)
      return !!(find && parseInt(find.edit, 0) === 1)
    },
    singleLabel() {
      return getSingleLabelByModuleName(this.module)
    },
  },
  watch: {
    // call again the method if the route changes
    perPage: 'perPageChange',
    queryFilter: 'queryFilterChange',
    statusFilter: 'statusFilterChange',
    searchQuery: 'searchQueryUpdate',
  },
  methods: {
    toggleQueryFilter(qf) {
      this.queryFilter = qf
    },
    perPageChange(perPage) {
      this.$emit('onPerPageChange', perPage)
    },
    queryFilterChange(queryFilter) {
      this.$emit('onQueryFilterChange', queryFilter)
    },
    searchQueryUpdate(searchQuery) {
      this.$emit('onSearchQueryUpdate', searchQuery)
    },
    createNewRecord(module) {
      this.$emit('onCreateNewRecordClicked', module)
    },
    exportRecord() {
      this.$emit('onExportRecordClicked', this.statusFilter.label)
    },
    statusFilterChange(status) {
      const statusFilter = {}
      if (status.value) statusFilter[this.statusField] = status.value
      this.$emit('onStatusFilterChange', statusFilter)
    },
  },
}
</script>

<style scoped>

</style>
