<template>
  <span
    class="d-inline-flex flex-row-reverse"
    style="direction: ltr"
  >
    {{ data.value | dateFormat('YYYY/MM/DD H:mm:s') }}
  </span>
</template>

<script>
import BaseDisplayType from './Base.vue'

export default {
  name: 'DatetimeDisplayType',
  extends: BaseDisplayType,
}
</script>

<style scoped>

</style>
