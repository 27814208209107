<template>
  <content-loader
    width="778"
    height="116"
    view-box="0 0 778 116"
  >
    <rect
      x="37"
      y="34"
      rx="0"
      ry="0"
      width="0"
      height="0"
    />
    <rect
      x="28"
      y="29"
      rx="0"
      ry="0"
      width="258"
      height="32"
    />
    <rect
      x="28"
      y="71"
      rx="0"
      ry="0"
      width="465"
      height="32"
    />
    <rect
      x="434"
      y="94"
      rx="0"
      ry="0"
      width="0"
      height="0"
    />
    <rect
      x="29"
      y="116"
      rx="0"
      ry="0"
      width="749"
      height="32"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'FaqListLoader',
  components: { ContentLoader },
}
</script>

<style scoped>

</style>
