<template>
  <div>
    <list-content
      ref="listContent"
      :list-data="listData"
      :list-columns="listColumns"
      :module="name"
    />
    <b-button
      v-if="more"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      size="sm"
      :disabled="loading"
      variant="outline-primary"
      class="mt-2"
      @click="page++"
    >
      <b-spinner
        v-if="loading"
        small
      />
      <span v-if="!loading">{{ $t('More') }} </span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { coreMethods } from '@/mixins'
import api from '@/apis/portal'
import { getLabelByModuleName } from '@/utils/utils'
import ListContent from '@/views/modules/core/list/ListContent.vue'

export default {
  name: 'EntityRelatedWidget',
  components: {
    ListContent,
    BButton,
    BSpinner,
  },
  directives: { Ripple },
  mixins: [coreMethods],
  props: {
    id: {
      type: String,
      default: () => '',
    },
    module: {
      type: String,
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      page: 0,
      more: false,
      loading: false,
      loader: false,
      listData: [],
      listColumns: [],
    }
  },
  watch: {
    page: 'getData',
  },
  created() {
    this.loader = true
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      api.fetchRelatedRecords({
        id: this.id,
        module: this.module,
        page: this.page,
        relatedModule: this.name,
        relatedModuleLabel: getLabelByModuleName(this.name),
      }).then(data => {
        this.loading = false
        this.loader = false
        this.more = data.more
        const { headers, records } = data
        this.listColumns = headers.slice(0, 3)
        this.listData = records
      })
    },
  },
}
</script>

<style>

</style>
