<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <template
      slot="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="getRules"
    >
      <b-form-input
        :id="name"
        v-model="value"
        :name="name"
        :state="errors.length > 0 ? false:null"
        :placeholder="label"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Base from './Base.vue'

export default {
  name: 'EmailUiType',
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
  },
  extends: Base,
}
</script>

<style scoped>

</style>
