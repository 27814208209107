<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <template
      slot="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="getRules"
    >
      <v-select
        key="value"
        v-model="value"
        :name="name"
        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
        :options="options"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Base from './Base.vue'

export default {
  name: 'PickListUiType',
  components: {
    ValidationProvider,
    BFormGroup,
    vSelect,
  },
  extends: Base,
  data() {
    return {

    }
  },
  computed: {
    options() {
      return this.type.picklistValues
    },
  },
  created() {
    const { defaultValue } = this.type
    const find = this.type.picklistValues.find(item => item.value === defaultValue)
    if (!this.value && find) this.value = find
  },
}
</script>

<style scoped>

</style>
