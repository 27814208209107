<template>
  <span>
    {{ data.value }}
  </span>
</template>

<script>
export default {
  name: 'BaseDisplayType',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
