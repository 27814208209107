<template>
  <component
    :is="loadComponent()"
    :id="id"
    :module="module"
  />
</template>

<script>
import List from '@/views/modules/core/list/List.vue'
import BeforeListContent from '@/views/modules/core/list/BeforeListContent.vue'
import HelpDeskBeforeListContent from '@/views/modules/helpdesk/list/BeforeListContent.vue'
import FaqList from '@/views/modules/faq/list/List.vue'
import Detail from '@/views/modules/core/detail/Detail.vue'
import HelpDeskDetail from '@/views/modules/helpdesk/detail/Detail.vue'
import InventoryDetail from '@/views/modules/inventory/details/Detail.vue'
import InventoryEdit from '@/views/modules/inventory/edit/Edit.vue'
import Error404 from '@/views/modules/core/error/Error404.vue'
import { coreMethods } from '@/mixins'

export default {
  name: 'ComponentLoader',
  components: {
    List,
    BeforeListContent,
    HelpDeskBeforeListContent,
    FaqList,
    Detail,
    HelpDeskDetail,
    InventoryDetail,
    InventoryEdit,
    Error404,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  methods: {
    loadComponent() {
      const moduleName = this.module
      const params = this.$route.path.split('/').filter(item => item !== '' && item !== moduleName)
      const componentName = params[0].charAt(0).toUpperCase() + params[0].slice(1)
      return this.getComponent(this, componentName, moduleName, 'Error404')
    },
  },
}
</script>

<style scoped>

</style>
