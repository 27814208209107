<template>
  <history-loader v-if="!historyData.length" />
  <app-timeline v-else>
    <app-timeline-item
      v-for="(update,key) in historyData"
      :key="key"
    >
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
        <h6>{{ update.modifieduser.label }}</h6>
        <small
          class="text-muted"
          style="direction: ltr"
        >{{ update.modifiedtime | dateFormat('YYYY/MM/DD H:mm:s') }}</small>
      </div>
      <strong v-if="update.status ==='created'">
        {{ $t(update.status) }}
      </strong>
      <p
        v-for="(value,key2) in update.values"
        :key="key2"
      >
        <span v-if="update.status ==='created'">
          {{ key2 }} <strong>
            <component
              :is="getDisplayType(value.info.type,module)"
              :key="key"
              :info="value.info"
              :data="{value:value.current}"
            />
          </strong>
        </span>
        <span v-else>
          <strong>{{ key2 }}</strong>
          <span> {{ $t(update.status) }} </span>
          <span v-if="value.previous"> {{ $t('from') }} </span>
          <strong>
            <component
              :is="getDisplayType(value.info.type,module)"
              :key="key"
              :info="value.info"
              :data="{value:value.previous}"
            />
          </strong>
          <span> {{ $t('in') }} </span>
          <strong>
            <component
              :is="getDisplayType(value.info.type,module)"
              :key="key"
              :info="value.info"
              :data="{value:value.current}"
            />
          </strong>
        </span>

      </p>
    </app-timeline-item>
    <b-button
      v-if="more"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      size="sm"
      :disabled="loading"
      variant="outline-primary"
      @click="page++"
    >
      <b-spinner
        v-if="loading"
        small
      />
      <span v-if="!loading">{{ $t('More') }} </span>
    </b-button>
  </app-timeline>
</template>

<script>
import {
  BImg, BMedia, BAvatar, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import HistoryLoader from '@/views/modules/core/loader/History.vue'

import BooleanDisplayType from '@/views/modules/core/detail/displaytypes/Boolean.vue'
import CurrencyDisplayType from '@/views/modules/core/detail/displaytypes/Currency.vue'
import DateDisplayType from '@/views/modules/core/detail/displaytypes/Date.vue'
import DatetimeDisplayType from '@/views/modules/core/detail/displaytypes/Datetime.vue'
import DoubleDisplayType from '@/views/modules/core/detail/displaytypes/Double.vue'
import EmailDisplayType from '@/views/modules/core/detail/displaytypes/Email.vue'
import IntegerDisplayType from '@/views/modules/core/detail/displaytypes/Integer.vue'
import FileDisplayType from '@/views/modules/core/detail/displaytypes/File.vue'
import MultipicklistDisplayType from '@/views/modules/core/detail/displaytypes/Multipicklist.vue'
import OwnerDisplayType from '@/views/modules/core/detail/displaytypes/Owner.vue'
import PasswordDisplayType from '@/views/modules/core/detail/displaytypes/Password.vue'
import PercentageDisplayType from '@/views/modules/core/detail/displaytypes/Percentage.vue'
import PhoneDisplayType from '@/views/modules/core/detail/displaytypes/Phone.vue'
import PicklistDisplayType from '@/views/modules/core/detail/displaytypes/Picklist.vue'
import ReferenceDisplayType from '@/views/modules/core/detail/displaytypes/Reference.vue'
import SalutationDisplayType from '@/views/modules/core/detail/displaytypes/Salutation.vue'
import StringDisplayType from '@/views/modules/core/detail/displaytypes/String.vue'
import TextDisplayType from '@/views/modules/core/detail/displaytypes/Text.vue'
import TimeDisplayType from '@/views/modules/core/detail/displaytypes/Time.vue'
import UrlDisplayType from '@/views/modules/core/detail/displaytypes/Url.vue'

import { coreMethods } from '@/mixins'
import api from '@/apis/portal'

export default {
  name: 'HistoryRelatedWidget',
  components: {
    HistoryLoader,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BMedia,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BButton,
    BSpinner,
    AppTimeline,
    AppTimelineItem,
    BooleanDisplayType,
    CurrencyDisplayType,
    DateDisplayType,
    DatetimeDisplayType,
    DoubleDisplayType,
    EmailDisplayType,
    IntegerDisplayType,
    FileDisplayType,
    MultipicklistDisplayType,
    OwnerDisplayType,
    PasswordDisplayType,
    PercentageDisplayType,
    PhoneDisplayType,
    PicklistDisplayType,
    ReferenceDisplayType,
    SalutationDisplayType,
    StringDisplayType,
    TextDisplayType,
    TimeDisplayType,
    UrlDisplayType,
  },
  directives: { Ripple },
  mixins: [coreMethods],
  props: {
    id: {
      type: String,
      default: () => '',
    },
    module: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      page: 0,
      more: true,
      loading: false,
      historyData: [],
    }
  },
  watch: {
    page: 'getHistory',
  },
  created() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      this.loading = true
      api.fetchHistory({
        id: this.id,
        module: this.module,
        page: this.page,
      }).then(data => {
        this.loading = false
        data.forEach(item => {
          if (item.status === 'created') this.more = false
        })
        this.historyData = this.historyData.concat(data)
      })
    },
  },
}
</script>

<style>

</style>
