<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <template
      slot="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="getRules"
    >
      <v-select
        key="id"
        v-model="value"
        :name="name"
        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
        :options="options"
        @search="fetchOptions"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import api from '@/apis/portal'
import Base from './Base.vue'

export default {
  name: 'ReferenceUiType',
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
  },
  extends: Base,
  data() {
    return {
      options: [],
    }
  },
  methods: {
    fetchOptions(search, loading) {
      if (search && search.length >= 3) {
        loading(true)
        api.fetchReferenceRecords({
          module: this.type.refersTo[0],
          query: search,
        }).then(data => {
          loading(false)
          this.options = data
        }).catch(() => {
          loading(false)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
