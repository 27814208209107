<template>
  <b-badge
    v-if="data.value"
    :style="`background-color:${color};color:white`"
    pill
    :variant="!color ? 'light-secondary' : '' "
  >
    {{ data.value }}
  </b-badge>
  <span v-else>
    {{ data.value }}
  </span>

</template>

<script>
import { BBadge } from 'bootstrap-vue'
import BaseDisplayType from './Base.vue'

export default {
  name: 'PicklistDisplayType',
  components: {
    BBadge,
  },
  extends: BaseDisplayType,
  computed: {
    color() {
      const { info, data } = this
      if (info.colors[data.value]) {
        return info.colors[data.value]
      }
      const find = info.picklistValues.find(item => item.label === data.value)
      if (find && info.colors[find.value]) {
        return info.colors[find.value]
      }
      return null
    },
  },
}
</script>

<style scoped>

</style>
