<template>
  <b-row
    v-if="!getFields.length"
    class="d-flex justify-content-center mb-3"
  >
    <b-spinner
      variant="info"
      label="Loading..."
    />
  </b-row>
  <b-row
    v-else
  >
    <b-col
      v-for="(field,key) in getFields"
      :key="key"
      :md="field.column"
    >
      <component
        :is="getUiType(field.type.name,module)"
        :name="field.name"
        :label="field.label"
        :type="field.type"
        :required="field.mandatory"
      />
    </b-col>
    <!-- submit button -->
    <b-col
      v-if="submitButton"
      md="12"
      class="mt-2"
    >
      <b-button
        variant="success"
        type="submit"
        :disabled="loading"
        @click.prevent="submitButtonClick"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <span v-if="!loading">{{ $t('Save') }}</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'
import BooleanUiType from '@/views/modules/core/edit/uitypes/Boolean.vue'
import CurrencyUiType from '@/views/modules/core/edit/uitypes/Currency.vue'
import DateUiType from '@/views/modules/core/edit/uitypes/Date.vue'
import DoubleUiType from '@/views/modules/core/edit/uitypes/Double.vue'
import EmailUiType from '@/views/modules/core/edit/uitypes/Email.vue'
import IntegerUiType from '@/views/modules/core/edit/uitypes/Integer.vue'
import FileUiType from '@/views/modules/core/edit/uitypes/File.vue'
import MultipicklistUiType from '@/views/modules/core/edit/uitypes/Multipicklist.vue'
import OwnerUiType from '@/views/modules/core/edit/uitypes/Owner.vue'
import PasswordUiType from '@/views/modules/core/edit/uitypes/Password.vue'
import PercentageUiType from '@/views/modules/core/edit/uitypes/Percentage.vue'
import PhoneUiType from '@/views/modules/core/edit/uitypes/Phone.vue'
import PicklistUiType from '@/views/modules/core/edit/uitypes/Picklist.vue'
import ReferenceUiType from '@/views/modules/core/edit/uitypes/Reference.vue'
import SalutationUiType from '@/views/modules/core/edit/uitypes/Salutation.vue'
import StringUiType from '@/views/modules/core/edit/uitypes/String.vue'
import TextUiType from '@/views/modules/core/edit/uitypes/Text.vue'
import TimeUiType from '@/views/modules/core/edit/uitypes/Time.vue'
import UrlUiType from '@/views/modules/core/edit/uitypes/Url.vue'

export default {
  name: 'FormFields',
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    BooleanUiType,
    CurrencyUiType,
    DateUiType,
    DoubleUiType,
    EmailUiType,
    IntegerUiType,
    FileUiType,
    MultipicklistUiType,
    OwnerUiType,
    PasswordUiType,
    PercentageUiType,
    PhoneUiType,
    PicklistUiType,
    ReferenceUiType,
    SalutationUiType,
    StringUiType,
    TextUiType,
    TimeUiType,
    UrlUiType,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: () => '',
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    submitButton: {
      type: Boolean,
      default: () => false,
    },
    useCustomFields: {
      type: Boolean,
      default: () => false,
    },
    customFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fields: {},
    }
  },
  computed: {
    getFields() {
      let formFields = this.customFields
      if (!this.useCustomFields) formFields = this.fields
      const fields = []
      const textFields = []
      Object.keys(formFields).forEach(key => {
        const field = formFields[key]
        field.column = 6
        if (field.editable) {
          if (field.type.name === 'text') {
            field.column = 12
            textFields.push(field)
          } else {
            fields.push(field)
          }
        }
      })
      return fields.concat(textFields)
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      this.describeModule()
    },
    describeModule() {
      api.describeModule({ module: this.module }).then(data => {
        this.fields = data.fields
        this.$emit('onFetchModuleData', data)
      })
    },
    submitButtonClick() {
      this.$emit('onSubmitButtonClick')
    },
  },
}
</script>

<style scoped>

</style>
