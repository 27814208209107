<template>
  <div class="text-truncate">
    {{ data.value === 1 || data.value === '1' || data.value === 'on' || data.value === 'Yes' ? $t('Yes') : $t('No') }}
  </div>
</template>

<script>
import BaseCellType from './Base.vue'

export default {
  name: 'BooleanCellType',
  extends: BaseCellType,
}
</script>

<style scoped>

</style>
