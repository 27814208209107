<template>
  <comments-loader v-if="loading" />
  <b-row v-else>
    <!-- Leave a Comment -->
    <b-col
      cols="12"
    >
      <h6 class="section-label">
        {{ $t('Leave a Comment') }}
      </h6>
      <b-card>
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group class="mb-2">
                <b-form-textarea
                  v-model="content"
                  name="content"
                  rows="4"
                  :placeholder="$t('Leave a Comment hear')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                class="mr-2  btn bg-success bg-lighten-2"
                :disabled="content.length < 3"
                @click="postComment"
              >
                <feather-icon
                  icon="SendIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Post comments') }}</span>
              </b-button>
              <b-button
                v-show="isSupportDocument"
                class="btn bg-danger bg-lighten-2"
                @click="uploadDocument"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  class="mr-50"
                />
                <span class="align-middle">{{ $t('Upload attachment') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <!--/ Leave a Comment -->
    <b-col
      cols="12"
    >
      <div class="media-list media-bordered">
        <b-media
          v-for="(comment,key) in comments"
          :key="key"
          left-align
          class="text-left"
        >
          <template #aside>
            <b-avatar
              size="40"
              :src="comment.creator.img"
              variant="light-primary"
            >
              <feather-icon
                v-if="!comment.creator.name"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            {{ comment.creator.name }}
          </h4>
          <b-card-text
            v-if="comment.isDocument"
            class="text-break"
          >
            {{ comment.content }}
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="downloadFile(comment)"
            >
              <feather-icon icon="DownloadIcon" />
            </b-button>
          </b-card-text>
          <b-card-text
            v-else
            class="text-break"
            v-html="comment.content"
          />
          <small class="float-right">{{ comment.time|dateFormat('H:mm:s YY/M/D') }}</small>
        </b-media>
      </div>
      <b-button
        v-if="more"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        :disabled="loading"
        variant="outline-primary"
        class="mt-2"
        @click="page++"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <span v-if="!loading">{{ $t('More') }} </span>
      </b-button>
    </b-col>
  </b-row>

</template>

<script>
import {
  BCard, BMedia, BCardText, BAvatar, BCol, BRow, BButton, BForm, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import api from '@/apis/portal'
import { getUserData } from '@/auth/utils'
import CommentsLoader from '@/views/modules/core/loader/Comments.vue'
import { nl2br } from '@/filters/text'

export default {
  name: 'CommentsRelatedWidget',
  components: {
    CommentsLoader,
    BCard,
    BMedia,
    BCardText,
    BAvatar,
    BCol,
    BRow,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      default: () => '',
    },
    module: {
      type: String,
      default: () => '',
    },
    isSupportDocument: {
      type: Boolean,
      default: () => false,
    },
    newDocument: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      page: 0,
      more: false,
      loading: false,
      loader: false,
      comments: [],
      commentsData: [],
      documentsData: [],
      content: '',
    }
  },
  watch: {
    page: 'getComments',
    newDocument: 'addDocument',
  },
  created() {
    this.loader = true
    this.fetchData()
  },
  methods: {
    async getComments(id, module, page) {
      const params = {
        id,
        module,
        relatedModule: 'ModComments',
        page,
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        api.fetchRelatedRecords(params).then(data => {
          this.loading = false
          this.loader = false
          this.more = data.more
          this.commentsData = data.comments
          resolve()
        }).catch(reject)
      })
    },
    async getDocuments(id, module, page) {
      const params = {
        id,
        module,
        relatedModule: 'Documents',
        relatedModuleLabel: 'Documents',
        page,
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        api.fetchRelatedRecords(params).then(data => {
          this.loading = false
          this.loader = false
          this.more = data.more
          this.documentsData = data.documents
          resolve()
        }).catch(reject)
      })
    },
    prepareData() {
      // create comments
      const items = []
      this.commentsData.forEach(item => {
        let creator = {}
        if (item.customer === '') {
          creator = {
            name: item.creator.label,
          }
          if (item.creator.image && item.creator.image.imagePath) {
            creator.img = item.creator.image.imagePath
          }
        } else {
          creator = {
            name: getUserData().fullName,
            img: getUserData().avatar,
          }
        }
        items.push({
          id: item.id,
          isDocument: false,
          content: nl2br(item.commentcontent),
          subContent: '',
          left: (item.customer === ''),
          time: item.createdtime,
          creator,
        })
      })
      this.documentsData.forEach(item => {
        let creator = {}
        if (item.source !== 'CUSTOMER PORTAL') {
          creator = {
            name: item.assigned_user_id.label,
          }
          if (item.assigned_user_id.image && item.assigned_user_id.image) {
            creator.img = item.assigned_user_id.image.imagePath
          }
        } else {
          creator = {
            name: getUserData().fullName,
            img: getUserData().avatar,
          }
        }
        items.push({
          id: item.id,
          isDocument: true,
          content: item.filename,
          filename: item.filename,
          filelocationtype: item.filelocationtype,
          subContent: item.notes_title,
          left: item.source !== 'CUSTOMER PORTAL',
          time: item.createdtime,
          creator,
        })
      })
      // sort items
      this.comments = items.sort((a, b) => b.time.localeCompare(a.time))
    },
    async fetchData() {
      await this.getComments(this.id, this.module, 0)
      if (this.isSupportDocument) await this.getDocuments(this.id, this.module, 0)
      this.prepareData()
    },
    postComment() {
      api.addComment(
        {
          related_to: this.id,
          commentcontent: this.content,
        },
      ).then(data => {
        const creator = {
          name: getUserData().fullName,
          img: getUserData().avatar,
        }
        this.comments.unshift({
          id: data.id,
          isDocument: false,
          content: data.commentcontent,
          subContent: '',
          left: (data.customer === ''),
          time: data.createdtime,
          creator,
        })
      })
      this.content = ''
    },
    downloadFile(document) {
      this.$emit('downloadFile', document, this.id)
    },
    uploadDocument() {
      this.$emit('uploadDocument')
    },
    addDocument() {
      if (this.newDocument) this.comments.unshift(this.newDocument)
    },
  },
}
</script>

<style  lang="scss">
@import "@core/scss/base/pages/app-chat.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
