<template>
  <div class="text-truncate">
    <b-media
      v-if="src"
      vertical-align="center"
    >
      <template #aside>
        <b-avatar
          size="32"
          :src="src"
          :text="avatarText(data.value.label)"
        />
      </template>
      <span class="d-block text-nowrap">
        {{ data.value.label }}
      </span>
    </b-media>
    <span v-else>
      {{ data.value.label }}
    </span>
  </div>
</template>

<script>
import {
  BMedia, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import BaseCellType from './Base.vue'

export default {
  name: 'OwnerCellType',
  components: {
    BMedia,
    BAvatar,
  },
  extends: BaseCellType,
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    src() {
      if (this.data.value.image) return this.data.value.image.imagePath ? this.data.value.image.imagePath : `data:${this.data.value.image.imagetype};base64,${this.data.value.image.imagedata}`
      return ''
    },
  },
}
</script>

<style scoped>

</style>
