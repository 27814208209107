<template>
  <div
    class="text-truncate"
    style="direction: ltr"
  >
    {{ data.value | dateFormat('YYYY/MM/DD H:mm:s') }}
  </div>
</template>

<script>
import BaseCellType from './Base.vue'

export default {
  name: 'DatetimeCellType',
  extends: BaseCellType,
}
</script>

<style scoped>

</style>
