<template>
  <span>
    {{ data.value === 1 || data.value === '1' || data.value === 'on' || data.value === 'Yes' ? $t('Yes') : $t('No') }}
  </span>
</template>

<script>
import BaseDisplayType from './Base.vue'

export default {
  name: 'BooleanDisplayType',
  extends: BaseDisplayType,
}
</script>

<style scoped>

</style>
