<template>

  <b-modal
    id="modal-edit-view"
    ref="modal-edit-view"
    size="lg"
    scrollable
    hide-footer
    :title="$t('Add Record',{ label : label })"
  >
    <edit-view-form
      ref="edit-view-form"
      :fields="fields"
      :module="module"
      @onSubmitForm="submitForm"
    />
  </b-modal>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { getSingleLabelByModuleName } from '@/utils/utils'
import EditViewForm from './EditViewForm.vue'

export default {
  name: 'EditViewModal',
  components: {
    BModal,
    EditViewForm,
  },
  props: {
    module: {
      type: String,
      default: () => '',
    },
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      label: '',
    }
  },
  created() {
    this.label = getSingleLabelByModuleName(this.module)
  },
  methods: {
    submitForm(data) {
      this.$emit('onSubmitForm', data)
    },
  },
}
</script>

<style scoped>

</style>
