<template>
  <content-loader
    width="400"
    height="100"
    view-box="0 0 400 100"
    primary-color="#f5f5f5"
    secondary-color="#dbdbdb"
  >
    <rect
      x="20"
      y="20"
      rx="5"
      ry="5"
      width="64"
      height="63"
    />
    <rect
      x="105"
      y="20"
      rx="5"
      ry="5"
      width="250"
      height="12"
    />
    <rect
      x="105"
      y="40"
      rx="5"
      ry="5"
      width="180"
      height="12"
    />
    <rect
      x="105"
      y="60"
      rx="5"
      ry="5"
      width="125"
      height="12"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'HistoryLoader',
  components: { ContentLoader },
}
</script>

<style scoped>

</style>
