<template>
  <content-loader
    width="400"
    height="160"
    view-box="0 0 400 160"
  >
    <rect
      x="0"
      y="13"
      rx="4"
      ry="4"
      width="400"
      height="9"
    />
    <rect
      x="0"
      y="29"
      rx="4"
      ry="4"
      width="100"
      height="8"
    />
    <rect
      x="0"
      y="50"
      rx="4"
      ry="4"
      width="400"
      height="10"
    />
    <rect
      x="0"
      y="65"
      rx="4"
      ry="4"
      width="400"
      height="10"
    />
    <rect
      x="0"
      y="79"
      rx="4"
      ry="4"
      width="100"
      height="10"
    />
    <rect
      x="0"
      y="99"
      rx="5"
      ry="5"
      width="400"
      height="200"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'DetailLoader',
  components: { ContentLoader },
}
</script>

<style scoped>

</style>
