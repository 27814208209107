<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-card v-if="showLoader">
      <InvoiceLoader v-if="showLoader" />
    </b-card>
    <b-row
      v-if="recordData && !showLoader"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <b-row>

              <!-- Header: Left Content -->
              <b-col
                cols="12"
                md="6"
              >
                <div class="logo-wrapper">
                  <PortalLogo />
                  <h3 class="text-primary invoice-logo">
                    {{ $t($store.state.app.title) }}
                  </h3>
                </div>
                <b-card-text class="mb-25">
                  {{ $store.state.portal.companyDetails.country }}   {{ $store.state.portal.companyDetails.city }}
                </b-card-text>
                <b-card-text class="mb-25">
                  {{ $store.state.portal.companyDetails.address }}  {{ $store.state.portal.companyDetails.code }}
                </b-card-text>
                <b-card-text class="mb-0">
                  {{ $store.state.portal.companyDetails.phone }}   {{ $store.state.portal.companyDetails.fax }}
                </b-card-text>
              </b-col>

              <!-- Header: Right Content -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-2"
              >
                <h4>
                  {{ title }}
                </h4>
                <table class="mt-2 mt-xl-0 w-100">
                  <tr
                    v-for="(data,key) in recordData"
                    :key="key"
                  >
                    <th class="pb-3">
                      <feather-icon
                        size="20"
                        icon="CheckIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-light">{{ data.label }}</span>
                    </th>
                    <td class="pb-3">
                      <div class="text-truncate font-weight-bold">
                        <component
                          :is="getDisplayType(data.type,$route.params.module)"
                          :key="key"
                          :info="data.info"
                          :data="data.data"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-col
              cols="12"
            />
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="lineItems"
            :fields="['product_name', 'quantity', 'listprice', 'netprice',]"
          >
            <template #head(product_name)>
              <b-card-text class="font-weight-bold mb-25">
                {{ $t('Product name') }}
              </b-card-text>
            </template>
            <template #head(listprice)>
              <b-card-text class="font-weight-bold mb-25">
                {{ $t('List price') }}
              </b-card-text>
            </template>
            <template #head(netprice)>
              <b-card-text class="font-weight-bold mb-25">
                {{ $t('Net Price') }}
              </b-card-text>
            </template>
            <template #head(quantity)>
              <b-card-text class="font-weight-bold mb-25">
                {{ $t('Quantity') }}
              </b-card-text>
            </template>
            <template #cell(quantity)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.quantity|currencyFormat('') }}
              </b-card-text>
            </template>
            <template #cell(netprice)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.netprice|currencyFormat(currency) }}
              </b-card-text>
            </template>
            <template #cell(listprice)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.listprice|currencyFormat(currency) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">{{ $t("Salesperson") }}:</span>
                  <span class="ml-75">{{ salesPerson.label }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Subtotal') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalLineItems.hdnSubTotal|currencyFormat(currency) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Discount') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ (totalLineItems.hdnSubTotal-totalLineItems.totalAfterDiscount)|currencyFormat(currency) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Tax') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalLineItems.tax_totalamount|currencyFormat(currency) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      {{ $t('Total') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalLineItems.grandTotal|currencyFormat(currency) }}
                    </p>
                  </div>
                  <div
                    v-if="hasPaymentButton"
                    class="invoice-total-item"
                  >
                    <p class="invoice-total-title">
                      {{ $t('Received') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalLineItems.received|currencyFormat(currency) }}
                    </p>
                  </div>
                  <hr
                    v-if="hasPaymentButton"
                    class="my-50"
                  >
                  <div
                    v-if="hasPaymentButton"
                    class="invoice-total-item"
                  >
                    <p class="invoice-total-title">
                      {{ $t('Balance') }}:
                    </p>
                    <p class="invoice-total-amount">
                      {{ totalLineItems.balance|currencyFormat(currency) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span>{{ rules }}</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        v-if="hasPaymentButton && totalLineItems.balance>0"
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: DOwnload -->
          <!--          <b-button-->
          <!--            v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
          <!--            variant="outline-secondary"-->
          <!--            class="mb-75"-->
          <!--            block-->
          <!--          >-->
          <!--            Download-->
          <!--          </b-button>-->

          <!-- Button: Print -->
          <!--          <b-button-->
          <!--            v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
          <!--            variant="outline-secondary"-->
          <!--            class="mb-75"-->
          <!--            block-->
          <!--          >-->
          <!--            Print-->
          <!--          </b-button>-->

          <!-- Button: Add Payment -->
          <b-button
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
            @click="addPayment"
          >
            <b-spinner
              v-if="addPaymentloading"
              small
            />
            <span v-if="!addPaymentloading"> {{ $t('Add Payment') }}</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>
<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, VBToggle, BSpinner,
} from 'bootstrap-vue'
import PortalLogo from '@/views/modules/core/partials/logo/LogoImg.vue'
import Ripple from 'vue-ripple-directive'
import api from '@/apis/portal'
import Detail from '@/views/modules/core/detail/Detail.vue'
import { getUserData } from '@/auth/utils'
import { coreMethods } from '@/mixins'
import InvoiceLoader from '@/views/modules/core/loader/Invoice.vue'
import { showToastNotification } from '@/utils/utils'
import i18n from '@/libs/i18n'

export default {
  name: 'InventoryDetail',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BSpinner,
    PortalLogo,
    InvoiceLoader,
  },
  extends: Detail,
  mixins: [coreMethods],
  data() {
    return {
      showLoader: true,
      lineItems: [],
      totalLineItems: {
        grandTotal: 0,
        hdnSubTotal: 0,
        tax_totalamount: 0,
        totalAfterDiscount: 0,
        balance: 0,
        received: 0,
      },
      title: '',
      salesPerson: '',
      dueDate: '',
      issueDate: '',
      currency: '',
      status: {},
      rules: '',
      addPaymentloading: false,
      hasPaymentButton: (this.module === 'Invoice' || this.module === 'PurchaseOrder'),
    }
  },
  methods: {
    onCrete() {
      this.getData(this.id, this.module)
    },
    afterGetRecordData(recordData) {
      const otherRecordData = {}
      Object.keys(recordData).forEach(key => {
        const record = recordData[key]
        const { value } = record.data
        if (record.name === 'subject') {
          this.title = value
        } else if (record.name === 'terms_conditions') {
          this.rules = value
        } else {
          otherRecordData[key] = record
        }
      })
      this.recordData = otherRecordData
      this.salesPerson = this.recordOwner
      this.currency = this.rawRecordData.currency
      this.lineItems = this.rawRecordData.LineItems
      this.totalLineItems = this.rawRecordData.TotalLineItems
      if (this.rawRecordData.record.source === 'CUSTOMER PORTAL') {
        this.creator = { name: getUserData().fullName, img: getUserData().avatar }
      } else {
        this.creator = { name: this.recordOwner, img: '' }
      }
      this.showLoader = false
    },
    addPayment() {
      this.addPaymentloading = true
      if (this.hasPaymentButton && this.totalLineItems.balance > 0) {
        api.addPayment({ module: this.module, parentId: this.id }).then(data => {
          this.addPaymentloading = false
          this.$router.push({ name: 'detail-view', params: { module: 'Payments', id: data.id } })
        })
      } else {
        this.addPaymentloading = false
        showToastNotification(i18n.t('Can not add payment for this record'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.invoice-preview,
.invoice-edit,
.invoice-add {
  .invoice-date-wrapper{
    .invoice-date-title{
      width: 15rem;
    }
  }
}
.invoice-preview,
.invoice-edit,
.invoice-add{
  .invoice-total-wrapper{
    max-width: 25rem;
  }
}

</style>

<style lang="scss">

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
