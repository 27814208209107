<template>
  <div class="text-truncate">
    {{ data.value }}
  </div>
</template>

<script>
export default {
  name: 'BaseCellType',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
  },
}
</script>

<style scoped>

</style>
