<template>
  <validation-observer
    ref="formRules"
  >
    <b-form
      ref="editForm"
    >
      <form-fields
        :module="module"
        :loading="loading"
        :submit-button="true"
        @onSubmitButtonClick="submitForm"
      />
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BForm,
} from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'
import FormFields from '@/views/modules/core/edit/partials/FormFields.vue'

export default {
  name: 'EditViewForm',
  components: {
    FormFields,
    BForm,
    ValidationObserver,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    submitForm() {
      this.$refs.formRules.validate().then(success => {
        if (success) {
          this.loading = true
          api.saveRecord({
            module: this.module,
            record: JSON.stringify(this.$store.state.portal.formData),
          }).then(data => {
            this.$store.commit('portal/UPDATE_FORM_DATA', {})
            this.$emit('onSubmitForm', data)
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
