<template>
  <b-card>
    <b-tabs>
      <b-tab
        v-for="(tab,key) in tabs"
        :key="key"
      >
        <template #title>
          <feather-icon :icon="getTabIcon(tab.name)" />
          <span>{{ $t(tab.name) }}</span>
        </template>
        <component
          :is="getRelatedModuleWidget(tab.name,module)"
          :id="id"
          :key="key"
          :module="module"
          :name="tab.name"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import { commonRelatedModules, getModuleIcon } from '@/utils/utils'
import HistoryRelatedWidget from './HistoryRelatedWidget.vue'
import DocumentsRelatedWidget from './DocumentsRelatedWidget.vue'
import CommentsRelatedWidget from './CommentsRelatedWidget.vue'
import EntityRelatedWidget from './EntityRelatedWidget.vue'

export default {
  name: 'DetailRelatedModules',
  components: {
    BCard,
    BTabs,
    BTab,
    HistoryRelatedWidget,
    DocumentsRelatedWidget,
    CommentsRelatedWidget,
    EntityRelatedWidget,
  },
  mixins: [coreMethods],
  props: {
    id: {
      type: String,
      default: () => '',
    },
    module: {
      type: String,
      default: () => '',
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    relatedRecords: {
      type: Object,
      default: () => {},
    },
  },
  created() {

  },
  methods: {
    getTabIcon(name) {
      switch (name) {
        case 'History':
          return 'GitPullRequestIcon'
        case 'Documents':
          return 'FileIcon'
        case 'ModComments':
          return 'MessageCircleIcon'
        default:
          return getModuleIcon(name)
      }
    },
    getRelatedModuleWidget(name, module) {
      let widgetName = name
      if (widgetName === 'ModComments') widgetName = 'comments'
      else if (!commonRelatedModules(widgetName)) widgetName = 'entity'
      return this.getRelatedWidget(widgetName, module)
    },
    getTabData(name) {
      return this.relatedRecords[name]
    },
  },
}
</script>
