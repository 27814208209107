<template>
  <!-- content -->
  <div class="blog-detail-wrapper">
    <b-card v-if="showTicketLoader">
      <TicketLoader />
    </b-card>
    <b-row v-show="!showTicketLoader">
      <!-- blogs -->
      <b-col
        cols="12"
        md="4"
      >
        <detail-header
          :record-data="recordData"
        />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="recordTitle"
        >
          <b-media no-body>
            <b-media-aside
              vertical-align="center"
              class="mr-50"
            >
              <b-avatar
                href="javascript:void(0)"
                size="24"
                :src="creator.img"
              />
            </b-media-aside>
            <b-media-body>
              <small>
                <b-link class="text-body">{{ creator.name }}</b-link>
              </small>
              <span class="text-muted ml-75 mr-50">|</span>
              <small class="text-muted">{{ createTime | dateFormat('H:mm YYYY/MM/DD') }}</small>
            </b-media-body>
          </b-media>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="blog-content my-1 py-25"
            v-html="descriptions"
          />
        </b-card>
        <b-card
          id="comment-section"
          class="chat-app-window"
        >
          <comments-related-widget
            :id="id"
            :module="module"
            :new-document="newDocument"
            :is-support-document="true"
            @uploadDocument="showUploadDocumentModal"
            @downloadFile="downloadFile"
          />
        </b-card>
        <component
          :is="getComponent(this,'UploadAttachmentModal',module)"
          ref="upload-attachment-modal"
          module="Documents"
          :parent-id="recordId"
          @onSubmitForm="submitUploadAttachment"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BBadge,
} from 'bootstrap-vue'
import Detail from '@/views/modules/core/detail/Detail.vue'
import { getUserData } from '@/auth/utils'
import UploadAttachmentModal from '@/views/modules/core/edit/UploadAttachmentModal.vue'
import TicketLoader from '@/views/modules/core/loader/Ticket.vue'
import DetailHeader from '@/views/modules/core/detail/DetailHeader.vue'
import CommentsLoader from '@/views/modules/core/loader/Comments.vue'
import { coreMethods } from '@/mixins'
import CommentsRelatedWidget from '@/views/modules/core/detail/CommentsRelatedWidget.vue'
import { nl2br } from '@/filters/text'

export default {
  name: 'HelpDeskDetail',
  components: {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BBadge,
    UploadAttachmentModal,
    CommentsRelatedWidget,
    TicketLoader,
    DetailHeader,
    CommentsLoader,
  },
  extends: Detail,
  mixins: [coreMethods],
  data() {
    return {
      creator: {},
      createTime: '',
      descriptions: '',
      commentsData: [],
      documentsData: [],
      newDocument: {},
      comments: [],
      status: {},
      showTicketLoader: true,
      showCommentsLoader: true,
    }
  },
  methods: {
    onCrete() {
      this.getData(this.id, this.module)
    },
    async getData(id, module) {
      await this.getDescribeModule(module)
      await this.getRecordData(id, module)
    },
    afterGetRecordData(recordData) {
      this.recordData = recordData
      delete this.recordData[this.rawRecordData.record.identifierName.label]
      Object.keys(recordData).forEach(key => {
        const record = recordData[key]
        const { value } = record.data
        if (record.name === 'ticketstatus') {
          this.status = { label: record.label, color: record.info.colors[value] }
        } else if (record.name === 'createdtime') {
          this.createTime = value
        } else if (record.name === 'description') {
          delete this.recordData[key]
          this.descriptions = nl2br(value)
        }
      })
      if (this.rawRecordData.record.source === 'CUSTOMER PORTAL') {
        this.creator = { name: getUserData().fullName, img: getUserData().avatar }
      } else {
        this.creator = { name: this.recordOwner.label, img: this.recordOwner.image ? this.recordOwner.image.imagePath : '' }
      }
      this.showTicketLoader = false
    },
    showUploadDocumentModal() {
      this.$refs['upload-attachment-modal'].$refs['upload-attachment-modal'].show()
    },
    submitUploadAttachment(data) {
      this.$refs['upload-attachment-modal'].$refs['upload-attachment-modal'].hide()
      const creator = {
        name: getUserData().fullName,
        img: getUserData().avatar,
      }
      this.newDocument = {
        id: data.record.id,
        isDocument: true,
        content: data.record.filename,
        subContent: data.record.notes_title,
        left: data.record.source !== 'CUSTOMER PORTAL',
        time: data.record.createdtime,
        creator,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
