<template>
  <detail-loader v-if="!Object.keys(recordData).length" />
  <b-row v-else>
    <b-col
      cols="12"
      :xl="hasRelatedModules ? 8 : 12"
      :lg="hasRelatedModules ? 8 : 12"
      :md="hasRelatedModules ? 6 : 12"
    >
      <detail-header
        :title="recordTitle"
        :record-data="recordData"
      />
    </b-col>
    <b-col
      v-if="hasRelatedModules"
      cols="12"
      md="6"
      xl="4"
      lg="6"
    >
      <component
        :is="getComponent(this,'BeforeDetailRelatedModules',module)"
        :id="id"
        :module="module"
        :record-data="rawRecordData"
      />
      <related-modules-loader v-if="!relatedModules.length" />
      <component
        :is="getComponent(this,'DetailRelatedModules',module)"
        v-else
        :id="id"
        :module="module"
        :tabs="relatedModules"
        :related-records="relatedRecords"
      />
    </b-col>
    <b-col
      v-if="hasOtherRelatedModules"
      cols="12"
    >
      <related-modules-loader v-if="!otherRelatedModules.length" />
      <component
        :is="getComponent(this,'DetailRelatedModules',module)"
        v-else
        :id="id"
        :module="module"
        :tabs="otherRelatedModules"
        :related-records="relatedRecords"
      />
    </b-col>
  </b-row>
</template>

<script>
import api from '@/apis/portal'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import DetailLoader from '@/views/modules/core/loader/Detail.vue'
import DetailHeader from '@/views/modules/core/detail/DetailHeader.vue'
import PaymentsBeforeDetailRelatedModules from '@/views/modules/payments/detail/BeforeDetailRelatedModules.vue'
import DetailRelatedModules from '@/views/modules/core/detail/RelatedModules.vue'
import RelatedModulesLoader from '@/views/modules/core/loader/RelatedModules.vue'

import BooleanDisplayType from '@/views/modules/core/detail/displaytypes/Boolean.vue'
import CurrencyDisplayType from '@/views/modules/core/detail/displaytypes/Currency.vue'
import DateDisplayType from '@/views/modules/core/detail/displaytypes/Date.vue'
import DatetimeDisplayType from '@/views/modules/core/detail/displaytypes/Datetime.vue'
import DoubleDisplayType from '@/views/modules/core/detail/displaytypes/Double.vue'
import EmailDisplayType from '@/views/modules/core/detail/displaytypes/Email.vue'
import IntegerDisplayType from '@/views/modules/core/detail/displaytypes/Integer.vue'
import FileDisplayType from '@/views/modules/core/detail/displaytypes/File.vue'
import MultipicklistDisplayType from '@/views/modules/core/detail/displaytypes/Multipicklist.vue'
import OwnerDisplayType from '@/views/modules/core/detail/displaytypes/Owner.vue'
import PasswordDisplayType from '@/views/modules/core/detail/displaytypes/Password.vue'
import PercentageDisplayType from '@/views/modules/core/detail/displaytypes/Percentage.vue'
import PhoneDisplayType from '@/views/modules/core/detail/displaytypes/Phone.vue'
import PicklistDisplayType from '@/views/modules/core/detail/displaytypes/Picklist.vue'
import ReferenceDisplayType from '@/views/modules/core/detail/displaytypes/Reference.vue'
import SalutationDisplayType from '@/views/modules/core/detail/displaytypes/Salutation.vue'
import StringDisplayType from '@/views/modules/core/detail/displaytypes/String.vue'
import TextDisplayType from '@/views/modules/core/detail/displaytypes/Text.vue'
import TimeDisplayType from '@/views/modules/core/detail/displaytypes/Time.vue'
import UrlDisplayType from '@/views/modules/core/detail/displaytypes/Url.vue'
import { commonRelatedModules } from '@/utils/utils'

export default {
  name: 'Detail',
  components: {
    BRow,
    BCol,
    DetailHeader,
    DetailLoader,
    PaymentsBeforeDetailRelatedModules,
    DetailRelatedModules,
    RelatedModulesLoader,
    BooleanDisplayType,
    CurrencyDisplayType,
    DateDisplayType,
    DatetimeDisplayType,
    DoubleDisplayType,
    EmailDisplayType,
    IntegerDisplayType,
    FileDisplayType,
    MultipicklistDisplayType,
    OwnerDisplayType,
    PasswordDisplayType,
    PercentageDisplayType,
    PhoneDisplayType,
    PicklistDisplayType,
    ReferenceDisplayType,
    SalutationDisplayType,
    StringDisplayType,
    TextDisplayType,
    TimeDisplayType,
    UrlDisplayType,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      describeModule: {},
      moduleFields: [],
      rawRecordData: {},
      recordData: {},
      recordId: '',
      recordTitle: '',
      recordOwner: '',
      hasRelatedModules: true,
      relatedModules: [],
      otherRelatedModules: [],
      hasOtherRelatedModules: true,
      relatedRecords: {},
    }
  },
  watch: {
    // call again the method if the route changes
    module: 'onCreate',
    id: 'onCreate',
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      this.getData(this.id, this.module)
    },
    async getData(id, module) {
      await this.getDescribeModule(module)
      await this.getRecordData(this.id, this.module)
      this.getRelatedModules(this.module)
    },
    async getDescribeModule(module) {
      return new Promise((resolve, reject) => {
        api.describeModule({ module }).then(data => {
          this.describeModule = data
          this.moduleFields = this.describeModule.fields
          resolve()
        }).catch(reject)
      })
    },
    async getRecordData(id, module) {
      return new Promise((resolve, reject) => {
        api.fetchRecord({ id, module }).then(data => {
          this.rawRecordData = data
          const recordData = {}
          this.recordId = this.rawRecordData.record.id
          this.recordTitle = this.rawRecordData.record[this.rawRecordData.record.identifierName.label]
          this.recordOwner = this.rawRecordData.record[this.rawRecordData.editLabels.assigned_user_id]
          Object.values(this.moduleFields).forEach(field => {
            if (!this.skipFields.includes(field.name)) {
              const value = this.rawRecordData.record[field.label]
              recordData[field.label] = {
                name: field.name,
                label: field.label,
                type: field.type.name,
                data: { value },
                info: field.type,
              }
            }
          })
          this.afterGetRecordData(recordData)
          resolve()
        }).catch(reject)
      })
    },
    afterGetRecordData(recordData) {
      this.recordData = recordData
    },
    getRelatedModules(module) {
      this.relatedModules = []
      this.otherRelatedModules = []
      api.fetchRelatedModules({ module }).then(data => {
        Object.values(data).forEach(relatedModule => {
          if (commonRelatedModules(relatedModule.name)) this.relatedModules.push(relatedModule)
          else this.otherRelatedModules.push(relatedModule)
        })
        this.hasRelatedModules = this.relatedModules.length
        this.hasOtherRelatedModules = this.otherRelatedModules.length
      })
    },
  },
}
</script>

<style scoped>

</style>
