<template>

  <validation-observer ref="formRules">
    <b-form ref="uploadForm">
      <b-row class="d-flex align-items-center">
        <b-col
          cols="12"
        >
          <file-ui-type
            :type="{name:'file'}"
            :label="$t('File')"
            :accept="accept"
            :disabled="loading"
            :progress="progress"
            name="file"
            :required="true"
          />
          <b-form-text text-variant="danger">
            {{ $t('The maximum file size is',{ size: 10 }) }}
          </b-form-text>
        </b-col>
        <!-- submit button -->
      </b-row>
      <b-row class="mt-2">
        <b-col md="12">
          <b-button
            variant="success"
            type="submit"
            :disabled="loading"
            @click.prevent="submitForm"
          >
            <b-spinner
              v-if="loading"
              small
            />
            <span v-if="!loading">{{ $t('Save') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BForm, BButton, BSpinner, BFormText,
} from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import FileUiType from '../uitypes/File.vue'

export default {
  name: 'UploadForm',
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    ValidationObserver,
    FileUiType,
    BFormText,
  },
  mixins: [coreMethods],
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    accept: {
      type: String,
      default: () => '',
    },
    progress: {
      type: [String, Number],
      default: () => 0,
    },
  },
  methods: {
    submitForm() {
      this.$emit('onSubmitForm', this.$refs.formRules)
    },
  },
}
</script>

<style scoped>

</style>
