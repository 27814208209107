<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ title }}
              </h4>
            </div>
          </div>
        </div>
      </b-col>
      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="12"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr
            v-for="(data,key) in recordData"
            :key="key"
          >
            <th class="pb-3">
              <feather-icon
                size="20"
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-light">{{ data.label }}</span>
            </th>
            <td class="pb-3">
              <div class="text-truncate font-weight-bold">
                <component
                  :is="getDisplayType(data.type,$route.params.module)"
                  :key="key"
                  :info="data.info"
                  :data="data.data"
                />
              </div>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import { coreMethods } from '@/mixins'
import BooleanDisplayType from '@/views/modules/core/detail/displaytypes/Boolean.vue'
import CurrencyDisplayType from '@/views/modules/core/detail/displaytypes/Currency.vue'
import DateDisplayType from '@/views/modules/core/detail/displaytypes/Date.vue'
import DatetimeDisplayType from '@/views/modules/core/detail/displaytypes/Datetime.vue'
import DoubleDisplayType from '@/views/modules/core/detail/displaytypes/Double.vue'
import EmailDisplayType from '@/views/modules/core/detail/displaytypes/Email.vue'
import IntegerDisplayType from '@/views/modules/core/detail/displaytypes/Integer.vue'
import FileDisplayType from '@/views/modules/core/detail/displaytypes/File.vue'
import MultipicklistDisplayType from '@/views/modules/core/detail/displaytypes/Multipicklist.vue'
import OwnerDisplayType from '@/views/modules/core/detail/displaytypes/Owner.vue'
import PasswordDisplayType from '@/views/modules/core/detail/displaytypes/Password.vue'
import PercentageDisplayType from '@/views/modules/core/detail/displaytypes/Percentage.vue'
import PhoneDisplayType from '@/views/modules/core/detail/displaytypes/Phone.vue'
import PicklistDisplayType from '@/views/modules/core/detail/displaytypes/Picklist.vue'
import ReferenceDisplayType from '@/views/modules/core/detail/displaytypes/Reference.vue'
import SalutationDisplayType from '@/views/modules/core/detail/displaytypes/Salutation.vue'
import StringDisplayType from '@/views/modules/core/detail/displaytypes/String.vue'
import TextDisplayType from '@/views/modules/core/detail/displaytypes/Text.vue'
import TimeDisplayType from '@/views/modules/core/detail/displaytypes/Time.vue'
import UrlDisplayType from '@/views/modules/core/detail/displaytypes/Url.vue'

export default {
  name: 'DetailHeader',
  components: {
    BCard,
    BRow,
    BCol,
    BooleanDisplayType,
    CurrencyDisplayType,
    DateDisplayType,
    DatetimeDisplayType,
    DoubleDisplayType,
    EmailDisplayType,
    IntegerDisplayType,
    FileDisplayType,
    MultipicklistDisplayType,
    OwnerDisplayType,
    PasswordDisplayType,
    PercentageDisplayType,
    PhoneDisplayType,
    PicklistDisplayType,
    ReferenceDisplayType,
    SalutationDisplayType,
    StringDisplayType,
    TextDisplayType,
    TimeDisplayType,
    UrlDisplayType,
  },
  mixins: [coreMethods],
  props: {
    title: {
      type: String,
      default: () => '',
    },
    recordData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style>

</style>
