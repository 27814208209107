<template>

  <b-modal
    id="upload-attachment-modal"
    ref="upload-attachment-modal"
    size="md"
    scrollable
    hide-footer
    :title="$t('Add Record',{ label: singleLabel })"
  >
    <upload-attachment-form
      ref="upload-attachment-form"
      :module="module"
      :parent-id="parentId"
      @onSubmitForm="submitForm"
    />
  </b-modal>
</template>

<script>

import {
  BModal,
} from 'bootstrap-vue'
import { getSingleLabelByModuleName } from '@/utils/utils'
import UploadAttachmentForm from './UploadAttachmentForm.vue'

export default {
  name: 'UploadAttachmentModal',
  components: {
    BModal,
    UploadAttachmentForm,
  },
  props: {
    module: {
      type: String,
      default: () => '',
    },
    parentId: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    singleLabel() {
      return getSingleLabelByModuleName('HelpDesk')
    },
  },
  methods: {
    submitForm(data) {
      this.$emit('onSubmitForm', data)
    },
  },
}
</script>

<style scoped>

</style>
