<template>
  <content-loader
    width="1500"
    height="400"
    view-box="0 0 1500 400"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect
      x="21"
      y="38"
      rx="5"
      ry="5"
      width="1500"
      height="180"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'TicketLoader',
  components: { ContentLoader },
}
</script>

<style scoped>

</style>
