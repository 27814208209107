<template>
  <span>
    {{ data.value }}
  </span>
</template>

<script>
import BaseDisplayType from './Base.vue'

export default {
  name: 'SalutationDisplayType',
  extends: BaseDisplayType,
}
</script>

<style scoped>

</style>
