<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <template
      slot="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="getRules"
    >
      <b-input-group>
        <cleave
          :id="name"
          v-model="value"
          :name="name"
          :state="errors.length > 0 ? false:null"
          :placeholder="label"
          class="form-control"
          :raw="true"
          :options="options.number"
        />
        <b-input-group-append is-text>
          {{ $store.state.portal.companyDetails.currency }}
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Base from './Base.vue'

export default {
  name: 'CurrencyUiType',
  components: {
    ValidationProvider,
    Cleave,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
  },
  extends: Base,
  data() {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
}
</script>

<style scoped>

</style>
