<template>
  <span
    style="direction: ltr"
  >
    {{ data.value }}
  </span>
</template>

<script>
import BaseDisplayType from './Base.vue'

export default {
  name: 'TimeDisplayType',
  extends: BaseDisplayType,
}
</script>

<style scoped>

</style>
