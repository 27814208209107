<template>
  <validation-observer
    ref="formRules"
  >
    <b-form ref="editForm">
      <section class="invoice-add-wrapper">
        <b-row class="invoice-add">

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >

            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        {{ $t($store.state.app.title) }}
                      </h3>
                    </div>
                    <b-card-text class="mb-25">
                      {{ $store.state.portal.companyDetails.country }}   {{ $store.state.portal.companyDetails.city }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ $store.state.portal.companyDetails.address }}  {{ $store.state.portal.companyDetails.code }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ $store.state.portal.companyDetails.phone }}   {{ $store.state.portal.companyDetails.fax }}
                    </b-card-text>
                  </div>

                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">
                        {{ moduleLabel }}
                      </h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-form-input
                          v-model="options.currentDate"
                          disabled
                        />
                      </b-input-group>
                    </div>
                    <div
                      v-for="(field,key) in dateFields"
                      :key="key"
                      class="d-flex align-items-center mb-1"
                    >
                      <span class="invoice-title">
                        {{ field.label }}:
                      </span>
                      <component
                        :is="getUiType(field.type.name,module)"
                        :name="field.name"
                        :type="field.type"
                        :form-data="formData"
                        :required="field.mandatory"
                        class="invoice-edit-input"
                      />
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <form-fields
                  class="invoice-spacing"
                  :module="module"
                  :form-data="formData"
                  :loading="loading"
                  :submit-button="false"
                  :use-custom-fields="true"
                  :custom-fields="fields"
                  @onFetchModuleData="fetchModuleData"
                />
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in inventoryData.value.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">

                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            {{ $t('Item') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            {{ $t('Unit Price') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ $t('Qty') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            {{ $t('Total') }}
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">   {{ $t('Item') }}</label>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Item')"
                              rules="required"
                            >
                              <v-select
                                v-model="item.itemTitle"
                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                :options="itemsOptions"
                                label="itemTitle"
                                :clearable="false"
                                class="mb-2 item-selector-title"
                                :placeholder="$t('Select Item')"
                                @input="val => updateItemForm(index, val)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <label class="d-inline d-lg-none">{{ $t('Unit Price') }}</label>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Unit Price')"
                              rules="required"
                            >
                              <cleave
                                v-model="item.unitPrice"
                                :disabled="true"
                                class="form-control mb-2"
                                :raw="true"
                                :options="options.number"
                                placeholder="10,000"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">{{ $t('Qty') }}</label>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('Qty')"
                              rules="required"
                            >
                              <b-form-input
                                v-model="item.qty"
                                type="number"
                                class="mb-2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none">{{ $t('Total') }}</label>
                            <p class="mb-1">
                              {{ item.unitPrice * item.qty | currencyFormat(item.currency) }}
                            </p>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="5"
                          >
                            <label class="d-inline d-lg-none">{{ $t('Description') }}</label>
                            <b-form-textarea
                              v-model="item.description"
                              disabled
                              no-resize
                              class="mb-2 mb-lg-0"
                            />
                          </b-col>
                        </b-row>
                        <div
                          v-if="index > 0"
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  {{ $t('Add Item') }}
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    class="mt-md-6 d-flex justify-content-end"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Subtotal') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ subTotal | currencyFormat }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Discount') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ discount | currencyFormat }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Tax') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ tax | currencyFormat }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Total') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ total | currencyFormat }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions mt-md-0 mt-2"
          >

            <!-- Action Buttons -->
            <b-card>

              <!-- Button: Save -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mb-75"
                :disabled="loading"
                block
                @click.prevent="submitForm"
              >
                <b-spinner
                  v-if="loading"
                  small
                />
                <span v-if="!loading">{{ $t('Save') }}</span>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Logo from '@/views/modules/core/partials/logo/LogoImg.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BSpinner, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Edit from '@/views/modules/core/edit/Edit.vue'
import FormFields from '@/views/modules/core/edit/partials/FormFields.vue'
import api from '@/apis/portal'
import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'

const { numberFormat, localeDate } = require('@/utils/utils')

export default {
  name: 'InventoryEdit',
  components: {
    ValidationObserver,
    ValidationProvider,
    FormFields,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    vSelect,
    BSpinner,
    Logo,
    Cleave,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  extends: Edit,
  mixins: [heightTransition],
  data() {
    return {
      options: {
        currentDate: localeDate(),
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      dateFields: [],
      inventoryData: ref({
        // ? Set single Item in form for adding data
        items: [{
          id: '',
          itemTitle: '',
          unitPrice: 0,
          qty: 1,
          description: '',
          currency: '',
        }],
      }),
      itemsOptions: [],
      itemFormBlankItem: {
        item: null,
        unitPrice: 0,
        qty: 1,
        description: '',
        currency: '',
      },
    }
  },
  computed: {
    subTotal() {
      let total = 0
      this.inventoryData.value.items.forEach(item => {
        total += item.unitPrice * item.qty
      })
      return total
    },
    discount() {
      return 0
    },
    tax() {
      return 0
    },
    total() {
      return this.subTotal + this.tax - this.discount
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    onCreate() {
      api.fetchItemRecords().then(data => {
        Object.keys(data).forEach(module => {
          data[module].forEach(item => {
            const itemRecord = {
              id: item.id,
              itemTitle: item.label,
              unitPrice: numberFormat(item.unit_price, 0, '.', ','),
              qty: 1,
              description: item.description,
              currency: item.currency,
            }
            // if (module === 'Products') {
            //
            // }
            this.itemsOptions.push(itemRecord)
          })
        })
      })
    },
    fetchModuleData(moduleData) {
      const dateFields = []
      const fields = []
      this.moduleLabel = moduleData.label
      Object.values(moduleData.fields).forEach(field => {
        if (field.type.name === 'date') dateFields.push(field)
        else fields.push(field)
      })
      this.dateFields = dateFields
      this.fields = fields
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.inventoryData.value.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.inventoryData.value.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    updateItemForm(index, val) {
      const {
        id, itemTitle, unitPrice, qty, description, currency,
      } = val
      this.inventoryData.value.items[index].id = id
      this.inventoryData.value.items[index].itemTitle = itemTitle
      this.inventoryData.value.items[index].unitPrice = unitPrice
      this.inventoryData.value.items[index].qty = qty
      this.inventoryData.value.items[index].description = description
      this.inventoryData.value.items[index].currency = currency
      this.lineItemsData = this.inventoryData.value.items
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
.invoice-preview,
.invoice-edit,
.invoice-add {
  .invoice-total-wrapper {
    max-width: 14rem;
  }
}
.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
