<template>
  <div
    class="mb-3"
  >
    <div
      v-if="listData.length === 0"
      class="d-flex justify-content-center"
    >
      {{ $t('No matching records found') }}
    </div>
    <b-table
      v-show="listData.length > 0"
      ref="listTable"
      :items="listData"
      responsive
      hover
      :fields="listColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative list-view-table"
      @row-clicked="rowClicked"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template
        v-for="(column, key) in listColumns"
        v-slot:[`head(${column.key})`]="data"
      >
        <div
          :key="key"
          class="w-75"
        >
          <span class="text-truncate">{{ data.label.toUpperCase() }}</span>
        </div>
      </template>
      <template
        v-for="(column, key) in listColumns"
        v-slot:[`cell(${column.key})`]="data"
      >
        <component
          :is="getCellType(column.type,module)"
          :key="key"
          :info="column.info"
          :data="data"
        />
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`list-row-${data.item.id}-detail-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'detail-view', params: { module: module,id: data.item.id }})"
          />
          <b-tooltip
            :title="$t('Show')"
            :target="`list-row-${data.item.id}-detail-icon`"
          />
          <feather-icon
            v-if="module ==='Documents'"
            :id="`list-download-${data.item.id}-attachment-icon`"
            icon="DownloadIcon"
            size="16"
            class="mx-1"
            @click="downloadFile(data.item)"
          />
          <b-tooltip
            v-if="module ==='Documents'"
            :title="$t('Download file')"
            :target="`list-download-${data.item.id}-attachment-icon`"
          />
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BSpinner, BDropdown, BDropdownItem, BTooltip,
} from 'bootstrap-vue'
import BooleanCellType from '@/views/modules/core/list/celltypes/Boolean.vue'
import CurrencyCellType from '@/views/modules/core/list/celltypes/Currency.vue'
import DateCellType from '@/views/modules/core/list/celltypes/Date.vue'
import DatetimeCellType from '@/views/modules/core/list/celltypes/Datetime.vue'
import DoubleCellType from '@/views/modules/core/list/celltypes/Double.vue'
import EmailCellType from '@/views/modules/core/list/celltypes/Email.vue'
import IntegerCellType from '@/views/modules/core/list/celltypes/Integer.vue'
import FileCellType from '@/views/modules/core/list/celltypes/File.vue'
import MultipicklistCellType from '@/views/modules/core/list/celltypes/Multipicklist.vue'
import OwnerCellType from '@/views/modules/core/list/celltypes/Owner.vue'
import PasswordCellType from '@/views/modules/core/list/celltypes/Password.vue'
import PercentageCellType from '@/views/modules/core/list/celltypes/Percentage.vue'
import PhoneCellType from '@/views/modules/core/list/celltypes/Phone.vue'
import PicklistCellType from '@/views/modules/core/list/celltypes/Picklist.vue'
import ReferenceCellType from '@/views/modules/core/list/celltypes/Reference.vue'
import SalutationCellType from '@/views/modules/core/list/celltypes/Salutation.vue'
import StringCellType from '@/views/modules/core/list/celltypes/String.vue'
import TextCellType from '@/views/modules/core/list/celltypes/Text.vue'
import TimeCellType from '@/views/modules/core/list/celltypes/Time.vue'
import UrlCellType from '@/views/modules/core/list/celltypes/Url.vue'
import { coreMethods } from '@/mixins'

export default {
  name: 'ListContent',
  components: {
    BTable,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BooleanCellType,
    CurrencyCellType,
    DateCellType,
    DatetimeCellType,
    DoubleCellType,
    EmailCellType,
    IntegerCellType,
    FileCellType,
    MultipicklistCellType,
    OwnerCellType,
    PasswordCellType,
    PercentageCellType,
    PhoneCellType,
    PicklistCellType,
    ReferenceCellType,
    SalutationCellType,
    StringCellType,
    TextCellType,
    TimeCellType,
    UrlCellType,
  },
  mixins: [coreMethods],
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    listColumns: {
      type: Array,
      default: () => [],
    },
    module: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      sortBy: '',
      isSortDirDesc: false,
    }
  },
  watch: {
    // call again the method if the route changes
    sortBy: 'sortByChange',
    isSortDirDesc: 'isSortDirDescChange',
  },
  methods: {
    sortByChange(sortBy) {
      this.$emit('onSortByChange', sortBy)
    },
    isSortDirDescChange(isSortDirDesc) {
      this.$emit('onIsSortDirDescChange', isSortDirDesc)
    },
    rowClicked(item, index, event) {
      if (!event.target.classList.contains('feather-download')) {
        this.$router.push({ name: 'detail-view', params: { module: this.module, id: item.id } })
      }
    },
    downloadFile(document) {
      this.$emit('onDownloadClick', document)
    },
  },
}
</script>

<style scoped>

</style>
