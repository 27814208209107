<template>
  <content-loader
    width="1500"
    height="400"
    view-box="0 0 1500 400"
    primary-color="#f3f3f3"
    secondary-color="#ecebeb"
  >
    <rect
      height="5.5"
      rx="1"
      ry="1"
      width="1400"
      x="31"
      y="5"
    />
    <rect
      height="5.5"
      rx="1"
      ry="1"
      width="1400"
      x="31"
      y="15"
    />
    <circle
      cx="1480"
      cy="12"
      r="12"
    />
    <rect
      height="24"
      rx="0"
      ry="0"
      width="24"
      x="0"
      y="0"
    />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'CommentsLoader',
  components: { ContentLoader },
}
</script>

<style scoped>

</style>
