<template>
  <!-- Table Container Card -->
  <b-card-actions
    class="match-height"
    action-collapse
    no-body
  >
    <b-card-body>
      <component
        :is="getComponent(this,'BeforeListContent',module)"
        :module="module"
        :list-columns="listColumns"
        @onPerPageChange="perPageChange"
        @onCurrentPageChange="currentPageChange"
        @onQueryFilterChange="queryFilterChange"
        @onStatusFilterChange="statusFilterChange"
        @onSearchQueryUpdate="searchQueryUpdate"
        @onCreateNewRecordClicked="showEditView"
        @onExportRecordClicked="exportRecord"
      />
      <list-loader
        v-if="fetchData"
      />
      <list-content
        v-show="!fetchData"
        ref="listContent"
        :list-data="listData"
        :list-columns="listColumns"
        :module="module"
        @onSortByChange="sortByChange"
        @onIsSortDirDescChange="isSortDirDescChange"
        @onDownloadClick="downloadFile"
      />
      <list-pagination
        class="mx-2 mb-2"
        :per-page="perPage"
        :total-records="totalRecords"
        @onCurrentPageChange="currentPageChange"
      />
      <component
        :is="getComponent(this,'AfterListContent',module)"
        :module="module"
      />
      <component
        :is="getComponent(this,'EditViewModal',module)"
        ref="edit-view"
        :module="module"
        @onSubmitForm="submitForm"
      />
      <component
        :is="getComponent(this,'UploadAttachmentModal',module)"
        ref="upload-attachment-modal"
        module="Documents"
        @onSubmitForm="submitUploadAttachment"
      />
    </b-card-body>
  </b-card-actions>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import ListLoader from '@/views/modules/core/loader/List.vue'
import BeforeListContent from '@/views/modules/core/list/BeforeListContent.vue'
import HelpDeskBeforeListContent from '@/views/modules/helpdesk/list/BeforeListContent.vue'
import EditViewModal from '@/views/modules/core/edit/EditViewModal.vue'
import UploadAttachmentModal from '@/views/modules/core/edit/UploadAttachmentModal.vue'
import api from '@/apis/portal'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { coreMethods } from '@/mixins'
import axios from 'axios'
import { getInventoryModules, getUiLabelByModuleName } from '@/utils/utils'
import { toggleExportDataLoader } from '@/utils/loaders'
import ListContent from './ListContent.vue'
import ListPagination from './ListPagination.vue'

export default {
  name: 'List',
  components: {
    BCardActions,
    BCard,
    BCardBody,
    ListLoader,
    BeforeListContent,
    HelpDeskBeforeListContent,
    ListContent,
    ListPagination,
    EditViewModal,
    UploadAttachmentModal,
  },
  mixins: [coreMethods],
  props: {
    module: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      queryFilter: '',
      statusFilter: '',
      searchQuery: '',
      totalRecords: 0,
      fetchData: true,
      isSortDirDesc: true,
      listColumns: [],
      sortBy: '',
      source: '',
      CancelToken: '',
      apiData: [],
      listData: [],
    }
  },
  watch: {
    // call again the method if the route changes
    module: 'reFetchData',
    '$store.state.portal.modules': 'reFetchData',
  },
  created() {
    this.onCreated()
  },
  methods: {
    onCreated() {
      this.reFetchData()
    },
    perPageChange(perPage) {
      this.perPage = perPage
      this.reFetchData()
    },
    currentPageChange(currentPage) {
      this.currentPage = currentPage
      this.reFetchData()
    },
    queryFilterChange(queryFilter) {
      this.queryFilter = queryFilter
      this.reFetchData()
    },
    statusFilterChange(statusFilter) {
      this.statusFilter = statusFilter
      this.reFetchData()
    },
    searchQueryUpdate(searchQuery) {
      if (searchQuery && searchQuery.length > 3) {
        this.searchQuery = searchQuery
        this.reFetchData()
      }
    },
    sortByChange(sortBy) {
      this.sortBy = sortBy
      this.reFetchData()
    },
    isSortDirDescChange(isSortDirDesc) {
      this.isSortDirDesc = isSortDirDesc
      this.reFetchData()
    },
    reFetchData() {
      this.cancelFetchData()
      // this.$refs.refListTable.refresh()
      // this.updateModuleActions()
      this.fetchList().then(data => {
        this.fetchData = false
        this.apiData = data
        const {
          columnFields,
          records,
          count,
        } = this.apiData
        this.setListData(columnFields, records, count)
      })
    },
    fetchList() {
      this.label = this.getModuleByName(this.module, 'label')
      return new Promise(resolve => {
        if (this.label) {
          const q = {
            mode: this.queryFilter,
            pageLimit: this.perPage,
            page: this.currentPage - 1,
          }
          if (this.sortBy) {
            q.orderBy = this.sortBy
            q.order = this.isSortDirDesc ? 'DESC' : 'ASC'
          }
          if (this.statusFilter) {
            q.filter = this.statusFilter
          }
          if (this.$route.params.filter) q.filter = this.$route.params.filter
          const params = {
            module: this.module,
            label: this.label,
            q,
          }
          this.fetchData = true
          this.totalRecords = 0
          this.CancelToken = axios.CancelToken
          this.source = this.CancelToken.source()
          resolve(api.fetchRecords(params, this.source.token))
        } else {
          resolve({
            columnFields: {},
            records: {},
            count: 0,
          })
        }
      })
    },
    setListData(columnFields, records, count) {
      const columns = []
      Object.keys(columnFields).forEach(key => {
        if (!this.skipFields.includes(key)) {
          columns.push({
            key,
            label: columnFields[key].label,
            type: columnFields[key].type,
            info: columnFields[key].info,
            sortable: true,
          })
        }
      })
      columns.push({ key: 'actions', label: this.$t('Action') })
      this.listColumns = columns
      this.listData = records
      this.totalRecords = parseInt(count, 0)
    },
    cancelFetchData() {
      if (this.source) this.source.cancel()
    },
    showEditView(module) {
      if (module === 'Documents') {
        this.$refs['upload-attachment-modal'].$refs['upload-attachment-modal'].show()
      } else if (getInventoryModules(module)) {
        this.$router.push({ name: 'edit-view', params: { module } })
      } else {
        this.$refs['edit-view'].$refs['modal-edit-view'].show()
      }
    },
    submitUploadAttachment(data) {
      this.$refs['edit-view'].$refs['modal-edit-view'].hide()
      this.$router.push({ name: 'detail-view', params: { module: this.module, id: data.record.id } })
    },
    submitForm(data) {
      this.$refs['edit-view'].$refs['modal-edit-view'].hide()
      this.$router.push({ name: 'detail-view', params: { module: this.module, id: data.record.id } })
    },
    exportRecord(statusLabel) {
      const q = {
        mode: this.queryFilter,
      }
      let filter = {}
      if (this.statusFilter !== '') {
        filter = this.statusFilter
      }
      if (this.$route.params.filter) q.filter = this.$route.params.filter
      const params = {
        module: this.module,
        label: this.label,
        q,
        filter,
      }
      toggleExportDataLoader(true)
      api.exportRecords(params).then(data => {
        const fileName = `${this.$t(this.$route.meta.title)} ${getUiLabelByModuleName(this.module)}_${statusLabel}`// if you have the fileName header available
        this.createDownloadLink(fileName, data.filecontent)
        toggleExportDataLoader(false)
      })
    },
  },
}
</script>

<style scoped>

</style>
