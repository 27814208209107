<template>
  <div class="text-truncate">
    <b-badge
      v-for="(value,key) in getValues"
      :key="key"
      pill
      variant="light-secondary"
    >
      {{ value }}
    </b-badge>
  </div>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import BaseCellType from './Base.vue'

export default {
  name: 'MultipicklistCellType',
  components: {
    BBadge,
  },
  extends: BaseCellType,
  computed: {
    getValues() {
      return this.data.value ? this.data.value.split(',') : []
    },
  },
}
</script>

<style scoped>

</style>
