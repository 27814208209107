<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <template
      v-if="label"
      slot="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="getRules"
    >
      <date-picker
        :id="name"
        v-model="value"
        :name="name"
        :state="errors.length > 0 ? false:null"
        :placeholder="label"
        :locale="$store.state.appConfig.app.locale"
        :format="format"
        :display-format="displayFormat"
        clearable
        auto-submit
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import Base from './Base.vue'

export default {
  name: 'DateUiType',
  components: {
    ValidationProvider,
    BFormGroup,
  },
  extends: Base,
  computed: {
    format() { return this.type.format === 'yyyy-mm-dd' ? 'YYYY-MM-DD' : 'YYYY-MM-DD' },
    displayFormat() { return this.$store.state.appConfig.app.locale === 'fa' ? 'jYYYY-jMM-jDD' : 'YYYY-MM-DD' },
  },
}
</script>

<style scoped>

</style>
