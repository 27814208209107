import store from '@/store'
import * as moment from 'jalali-moment'

export const toggleExportDataLoader = status => {
  store.commit('portal/UPDATE_LOADERS', { exportData: status })
}

export const test = (date = null, format = 'YYYY/MM/DD') => {
  if (date === '') return ''
  let currentDate
  if (date === null) currentDate = moment()
  else currentDate = moment(date)
  return currentDate.locale(store.state.appConfig.app.locale).format(format)
}
