<template>
  <b-card
    v-if="recordData.isPayable"
    no-body
  >
    <b-card-body>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        block
        :disabled="loading"
        @click="onlinePayment"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <span v-if="!loading"> {{ $t('Online Payment') }}</span>
      </b-button>
    </b-card-body>
    <b-modal
      id="select-gateway-modal"
      ref="select-gateway-modal"
      size="md"
      scrollable
      hide-footer
      :title="$t('Select gateway')"
    >
      <div
        v-if="!payLoading"
        class="media-list"
      >
        <b-media
          v-for="(gateway,key) in gateways"
          :key="key"
          :right-align="!$store.state.appConfig.layout.isRTL"
          vertical-align="top"
          class="cursor-pointer"
          :class="{'text-right':!$store.state.appConfig.layout.isRTL,'text-left':$store.state.appConfig.layout.isRTL}"
          @click="payNow(gateway)"
        >
          <template #aside>
            <b-img
              :src="gateway.image"
              blank-color="#ccc"
              width="64"
              :alt="gateway.name"
            />
          </template>
          <h4 class="media-heading">
            {{ gateway.providertype }}
          </h4>
          <b-card-text>{{ gateway.name }}</b-card-text>
        </b-media>
      </div>
      <div
        v-else
        class="text-center"
      >
        <b-spinner
          small
        />
        <span class="sr-only">Loading...</span>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BButton, BSpinner, BMedia, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { coreMethods } from '@/mixins'
import api from '@/apis/portal'

export default {
  name: 'PaymentsBeforeDetailRelatedModules',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BSpinner,
    BMedia,
    BImg,
  },
  mixins: [coreMethods],
  props: {
    id: {
      type: String,
      default: () => '',
    },
    module: {
      type: String,
      default: () => '',
    },
    recordData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      payLoading: false,
      gateways: [],
    }
  },
  created() {
  },
  methods: {
    onlinePayment() {
      this.loading = true
      api.getGateways().then(data => {
        this.loading = false
        this.gateways = data
        this.$refs['select-gateway-modal'].show()
      })
    },
    payNow(gateway) {
      this.payLoading = true
      api.payNow({
        recordId: this.id,
        module: this.module,
        providerId: gateway.id,
      }).then(data => {
        window.open(data.url, '_blank')
        this.$refs['select-gateway-modal'].hide()
        this.payLoading = false
      }).catch(() => {
        this.payLoading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
