<template>
  <div class="text-truncate">
    {{ data.value }}
  </div>
</template>

<script>
import BaseCellType from './Base.vue'

export default {
  name: 'FileCellType',
  extends: BaseCellType,
}
</script>

<style scoped>

</style>
