<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            {{ $t('Frequently Asked Questions') }}
          </h2>
          <b-card-text class="mb-2">
            {{ $t('We have answered your frequently asked questions in a categorized way') }}
          </b-card-text>

          <!-- form -->
          <b-form
            class="faq-search-input"
            @submit.prevent=""
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                :placeholder="$t('Search faq...')"
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->
    <faq-list-loader
      v-if="fetchData"
    />
    <!-- frequently asked questions tabs pills -->
    <section
      v-else
      id="faq-tabs"
    >
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in faqData"
          :key="categoryName"
          :active="!index"
        >

          <!-- title -->
          <template #title>
            <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>{{ $t('You still have a question?') }}</h2>
          <b-card-text class="mb-3">
            {{ $t('If you cannot find a question in our FAQ, you can always contact us. We will answer to you shortly!') }}
          </b-card-text>
        </b-col>
        <b-col
          v-if="$store.state.portal.companyDetails.phone"
          sm="6"
        >
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>{{ $store.state.portal.companyDetails.phone }}</h4>
            <span class="text-body">{{ $t('We are always happy to help!') }}</span>
          </b-card>
        </b-col>
        <b-col
          v-if="$store.state.portal.companyDetails.email"
          sm="6"
        >
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>{{ $store.state.portal.companyDetails.email }}</h4>
            <span class="text-body">{{ $t('Best way to get answer faster!') }}</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>

import List from '@/views/modules/core/list/List.vue'
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar,
} from 'bootstrap-vue'
import FaqListLoader from '@/views/modules/faq/loader/List.vue'
import { groupBy } from '@/utils/utils'
import { nl2br } from '@/filters/text'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  name: 'FaqList',
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqListLoader,
    FaqQuestionAnswer,
  },
  extends: List,
  data() {
    return {
      faqSearchQuery: '',
      faqData: {},
      originalFaqData: {},
    }
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.searchFaqs()
      },
    },
  },
  methods: {
    onCreated() {
      this.fetchList().then(data => {
        this.apiData = data
        const { records } = this.apiData

        const groupByCategory = groupBy('faqcategories')
        const faqs = groupByCategory(records)
        const faqData = {}
        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const faqsKey in faqs) {
          const qandA = []
          // eslint-disable-next-line no-restricted-syntax,guard-for-in
          for (const faq of faqs[faqsKey]) {
            qandA.push({
              question: faq.question,
              ans: nl2br(faq.faq_answer),
            })
          }
          faqData[faqsKey] = {
            icon: 'CircleIcon',
            title: faqsKey,
            qandA,
          }
        }
        this.originalfaqData = faqData
        this.faqData = faqData
        this.fetchData = false
      })
    },
    searchFaqs() {
      if (this.originalfaqData) {
        const filteredData = {}
        const query = this.faqSearchQuery

        Object.entries(this.originalfaqData).forEach(entry => {
          const [categoryName, categoryObj] = entry
          // eslint-disable-next-line arrow-body-style
          const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
            return qAndAObj.question.includes(query) || qAndAObj.ans.includes(query)
          })
          if (filteredQAndAOfCategory.length) {
            filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
          }
        })
        this.faqData = filteredData
      }
    },
  },

}

</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
